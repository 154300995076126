  


   body {
    /* font-family: 'Poppins', sans-serif; */
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

body.light {
  background-color: #f9fdff;
  color: #00223b;
}

body.light h1, body.light h2, body.light h3, body.light h4, body.light h5 {
  color: #00223b;
}


body.light p {
  color: #00223b;
}



body.dark {
  background-color: #00223b;
  color: #f9fdff;
}

body.dark h1, body.dark h2, body.dark h3, body.dark h4, body.dark h5 {
  color: #FFF;
}

body.dark p {
  color: #f9fdff;
}

  
  
  .dark.all{
    background-color: #00223b;
  }
  .dark.light{
    background-color: #f9fdff;
  }


  .home {
    width: 100%;
    height: 95vh;
    background: linear-gradient(to bottom, rgba(20, 20, 21, 0.562), rgba(105, 63, 23, 0.595)),
    url('../../assets/images/LandingPage/homeimage.webp');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;

    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  
  


  

 
  .contnet {
text-align: center;
  }
  
  .contnet h1 {
    color: #FFF;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  
  .contnet p {
    width: 490px;
    margin: auto;
    color: #f9fdff;
text-align: center;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
  }
  
  .dwonloadBTN {
    width: 220px;
    height: 77px;
    border-radius: 50px;
    background: #cd5018;
    box-shadow: 1px 10px 20px 0px rgba(0, 0, 0, 0.50);
    color: #FFF;
text-align: center;
font-size: 22px;
font-style: normal;
font-weight: 700;
line-height: normal;
padding: 25px 30px;
margin-top: 25px;
  transition: background-color 0.3s, transform 0.3s;
   
  }


 .submitBtn{

    border-radius: 50px;
    background: #cd5018;
    box-shadow: 1px 10px 20px 0px rgba(0, 0, 0, 0.50);
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  transition: background-color 0.3s, transform 0.3s;
   
  }


 
  
  .dwonloadBTN:hover, .dwonloadBTN2:hover  ,.submitBtn:hover {
    background-color: #fb852c;
    transform: scale(1.05) ;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
  }
  .dwonloadBTN:focus ,.dwonloadBTN2:hover , .submitBtn:hover{
    background-color: #3190bc !important;
  }
  
  .services{
margin: 80px 0 0 0;
  }
  .serviceCol{
    margin-top: 40px;
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effect */
    padding: 2rem;

  }

  .serviceCol:hover {
    border-radius: 2rem;
    padding: 2rem;
    transform: translateY(-10px);
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px; 
  }
  .services h1 {
    font-size: 50px;
    font-weight: 600;
    line-height: 75px;
    margin: auto;
    text-align: center;
    color: #FFF;
position: relative;
  }

  .vectorImage {
  
    display: none; 
  }
 
  
  .services h5 {
    color: #f9fdff;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 20px;
    margin-top: 45px;
  }
  
  .services p {
    margin: auto;
    margin-bottom: 20px;
    color: #f9fdff;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }
  
  .IconBody {
    width: 113px;
    height: 113px;
    background-color: #cd5018;
    border-radius: 50%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .IconBody svg{
    color: #f9fdff;
    width: 90px !important;
    height: 80px !important;
  }
 
  
  .downloadSection {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
  margin-top: 45px;
  }
  .downloadContent{
    padding: 5rem;
    margin: auto;
    text-align: center;
  }
  .downloadSection p {
 
    color: #f9fdff;
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 50px;
    margin-top: 40px;
  }
  

  
  .IconBody img {
    width: 70px;
    height: 70px;
    margin: auto;
  }
  
  .about {
  margin: 10rem 2rem 4rem 2rem;
  
  }
  

  .about img{
    
width: 500px;
height: 500px;
border-radius: 3rem;
    }

    .about img:hover{
    
    box-shadow: 0 4px 8px rgba(9, 153, 172, 0.523) !important;
          }

  
  
  .aboutContent h2 {
    color: #f9fdff;
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin: 20px 0;
  }
  .aboutContent p {
    color: #f9fdff;
font-style: normal;
font-weight: 400;
line-height: 1.7rem;
  }
  
 

  .light .aboutContent *{
    color: #00223b;
      }
 


  .light {
    background-color: #f9fdff;

  }
  
  .dark {
    background-color: #00223b;
    
  }
  
  .light.services h1, .light.services h5, .light.services p {
    color: #00223b;
  }
  


  .light.downloadSection p   {
    color: #00223b;
  }
 

  
  
 
  
  .dark.services h1, .dark.services h5, .dark.services p  {
    color: #f9fdff;
  }


  
  @media (max-width: 1200px) and (min-width: 992px) {
 
    
      .contnet h1 {
        font-size: 40px;
        
      }
      .contnet p {
        max-width: 60%;
        font-size: 22px;
        line-height: 22px;
      }

  
    .aboutContent {
      width: 90%;
    }
  
  
  
    .storeButton {
      width: 200px;
    }
  }
  
  @media (max-width: 991px) and (min-width: 768px) {
  

 


    .contnet h1 {
      font-size: 33px;
      line-height: 40px;
      max-width: 100%;
    }
  
    .contnet p {
      max-width: 70%;
      font-size: 20px;
      line-height: 25px;
    }
 
.aboutContent{
  width: 90%;
  max-width: 100%;

 }
 
 
  
  
  }
  
  @media (max-width: 767px) and (min-width: 576px) {
    
   
  
    .contnet h1 {
      font-size: 37px;
      line-height: 35px;
    }
  
    .contnet p {
      max-width: 55%;
      margin-top: 1rem;
      font-size: 21px;
      line-height: 27px;
    }
    .services h1 {
      font-size: 45px;
      font-weight: 600;
      line-height: 75px;
      margin: auto;
      text-align: center;
      margin-bottom:80px;
      color: #FFF;
  
    }

 
    
  }
  
  @media (max-width: 575px) and (min-width: 481px) {
    
   
    .contnet h1 {
      font-size: 35px;
      line-height: 30px;
    }
  
    .contnet p {
      max-width:  60%;
      font-size: 18px;
      line-height: 18px;
      margin-top: 1rem;
    }

  

   
   .services{
    margin: 20px 0 ;
   }
     .serviceCol{
      margin: 20px;
    }
    .downloadSection{
      margin-top: 0;
   
    }
    .dwonloadBTN{
      margin-top: 10px;
    }
    .services h1 {
      font-size: 40px;
      font-weight: 600;
      line-height: 75px;
      margin: auto;
      text-align: center;
      color: #FFF;
    }
    .services p{
      width: 80%;
     max-width: 100%;
    }
  
  }
  

  


  @media (min-width: 290px) and (max-width: 480px) {
    
    .about {
      margin: 2rem;
      }
      .aboutContent h2{
        font-size: 25px;
        }

  
 .dwonloadBTN {
    margin-top: 0;
  }
 
 
  .dwonloadBTN{
    width: 200px;
  height: 54px;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;

  padding: 15px 34px;
  }

 .contnet{
  padding:2rem;
 }

    .contnet h1 {
    
      text-align: left;
      color:  #FFF;
font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: normal;

    }
  
    .contnet p {
      color: #f9fdff;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-align: left;
      margin-bottom: 20px;
   width: 100%;
    }

    .services{
      margin: 30px 0 1px 0;
        }
        .serviceCol{
      margin: 0;
        }


        

    .services h5{
      color: #f9fdff;
      text-align: left;
      margin-left: 7px;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
    }
    .services p{
      color: #f9fdff;
      text-align: left;
      margin-left: 7px;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 30px;
    }
    .IconBody{
   width: 90px;
   height: 90px;
   margin-left: 7px;
    }

    
.downloadContent {
 padding: 2rem;
  
  }

.downloadContent p{
margin-top: 40px;
font-size: 17px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-align: left;

}



.services h1 {

font-size: 35px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-bottom: 20px;
text-align: left;
margin-left: 7px;
max-width: 70%;
}
   
.whyChooseTitle {
  margin: auto;
  text-align: center;
  position: relative;
}

.whyChooseText {
  font-size: 60px;
}







.allRtl {
  text-align: right !important;
direction: rtl  !important;

}





.allRtl .contnet *{
  text-align: right;
}
.allRtl .contnet h1{
width: 100%;
}

.allRtl  .dwonloadBTN {

  text-align: center !important;
margin: 0; 
font-size: 25px;
padding: 10px 30px 0 0 ;
} 
.allRtl  .serviceCol {
  margin-right: 15px;
}
.allRtl.services  h1 {
  max-width: 85%;
  direction: rtl;
  text-align: right;
  margin-right: -20px;  
position: relative;
  
}
.allRtl.services  h5 {
font-size: 25px;
  
}
.allRtl.services  p{
  font-size: 20px;
    
  }
  

.allRtl.services .vectorImage{

margin-top: -10px;
right: -150px;
  transform: rotateY(200deg);
}
  

.allRtl .serviceCol *{
margin-right: 1px;

  text-align: right !important;

}
.allRtl .downloadContent p{
  margin-right: 1px;
font-size: 23px;
  text-align: right !important;
  margin-bottom: 30px;
}

.allRtl.downloadSection .dwonloadBTN{
  margin-top: 40px;
}
.allRtl .serviceCol .IconBody img{
margin: auto;
  
  }
  
 


  }


  @media (min-width: 290px) and (max-width: 440px) {
    .vectorImage {
      display: block; 
      position: relative; 
      top: auto;
      
      transform: none;
      width: 50%;
      margin: 0 140px 0  0 ;
      
    
    }

  
  }





  
  @media (min-width: 290px) and (max-width: 740px) {
   

    .MDBModalDialog{
      margin: 1.8rem;
    }

  
  }

