body {
  font-family: 'Poppins', sans-serif;
  background-color: transparent !important;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.arabic-font {
  /* font-family: 'Almarai', sans-serif; */
  /* font-family: 'Cairo', sans-serif; */
  /* font-family: "Alexandria", sans-serif; */
  font-family: "Noto Sans Arabic", sans-serif;


}

  .main-content {
    padding-top: 70px;
  }
