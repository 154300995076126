/* NotFound.module.css */
@import url("https://fonts.googleapis.com/css?family=Comfortaa:300,400,700");
.notFound, .four0four {
  font-family: "Comfortaa";
}


.containnner {
  

  height: 80vh;
  width: 100%;
  max-width: 100%;

  background-color: #00223b;
  overflow: hidden;
  display: flex;
  align-items: center;

 
}

.row{
  margin-left: 8rem;
}

@media (max-width: 700px) {
    .row {
     margin: 0;
    }}


.four0four {
  color: white;
  text-shadow: 0 0 1px rgba(255, 120, 250, 0.2), 0 0 2px rgba(250, 193, 150, 0.9), 0 0 70px #af5227;
  color: white;
  margin-top: -67px;
  font-size: 15em;
  text-align: right;
  min-width: 450px;
  z-index: 5;
}
.four0four:before {
  content: "404";
  position: absolute;
  color: #cd5018;
  mix-blend-mode: color-dodge;
  z-index: 1;
}

.notFound {
  color: white;
  text-shadow: 0 0 1px rgba(255, 170, 120, 0.2), 0 0 2px rgba(250, 207, 150, 0.9), 0 0 70px #cd5018;
  line-height: 110%;
  font-size: 3.75em;
}
.notFound:before {
  letter-spacing: 0.25px;
  content: "Page not found";
  position: absolute;
  color: #af5227;
  mix-blend-mode: color-dodge;
  z-index: 1;
}

.flicker1 {
  animation: 5s linear 3.25s flickr infinite;
}

.flicker2 {
  animation: 5s linear 1.25s flickr infinite;
}

.flicker3 {
  animation: 5s ease 1s flickr infinite;
}

.flicker4 {
  animation: 5s ease 2s flickr infinite;
}

.off {
  color: rgba(50, 50, 50, 0.25);
  border: none;
  text-shadow: none;
}

@keyframes flickr {
  0% {
    color: rgba(50, 50, 50, 0.25);
    border: none;
    text-shadow: none;
  }
  1% {
    color: white;
    text-shadow: 0 0 1px rgba(255, 208, 120, 0.2), 0 0 2px rgba(250, 205, 150, 0.9), 0 0 70px #af5227;
  }
  2% {
    color: rgba(50, 50, 50, 0.25);
    border: none;
    text-shadow: none;
  }
  8% {
    color: rgba(50, 50, 50, 0.25);
    border: none;
    text-shadow: none;
  }
  10% {
    color: white;
    text-shadow: 0 0 1px rgba(255, 208, 120, 0.2), 0 0 2px rgba(250, 205, 150, 0.9), 0 0 70px #af5227;
  }
  11% {
    color: rgba(50, 50, 50, 0.25);
    border: none;
    text-shadow: none;
  }
  12% {
    color: white;
    text-shadow: 0 0 1px rgba(255, 208, 120, 0.2), 0 0 2px rgba(250, 205, 150, 0.9), 0 0 70px #af5227;
  }
  13% {
    color: rgba(50, 50, 50, 0.25);
    border: none;
    text-shadow: none;
  }
  14% {
    color: white;
    text-shadow: 0 0 1px rgba(255, 208, 120, 0.2), 0 0 2px rgba(250, 205, 150, 0.9), 0 0 70px #af5227;
  }
  53% {
    color: white;
    text-shadow: 0 0 1px rgba(255, 208, 120, 0.2), 0 0 2px rgba(250, 205, 150, 0.9), 0 0 70px #af5227;
  }
  54% {
    color: rgba(50, 50, 50, 0.25);
    border: none;
    text-shadow: none;
  }
  58% {
    color: rgba(50, 50, 50, 0.25);
    border: none;
    text-shadow: none;
  }
  59% {
    color: white;
    text-shadow: 0 0 1px rgba(255, 208, 120, 0.2), 0 0 2px rgba(250, 205, 150, 0.9), 0 0 70px #af5227;
  }
  60% {
    color: rgba(50, 50, 50, 0.25);
    border: none;
    text-shadow: none;
  }
  61% {
    color: white;
    text-shadow: 0 0 1px rgba(255, 208, 120, 0.2), 0 0 2px rgba(250, 205, 150, 0.9), 0 0 70px #af5227;
  }
  100% {
    color: white;
    text-shadow: 0 0 1px rgba(255, 208, 120, 0.2), 0 0 2px rgba(250, 205, 150, 0.9), 0 0 70px #af5227;
  }
}
.fog8, .fog7, .fog6, .fog5, .fog4, .fog3, .fog2, .fog1 {
  position: absolute;
  border: none;
  border-radius: 100%;
  z-index: 400;
}

.fog1 {
  width: 100px;
  height: 100px;
  animation: 75s linear float infinite;
  box-shadow: inset 0 0 50px rgba(255, 255, 255, 0.8), 0 0 50px rgba(255, 255, 255, 0.5);
}

.fog2 {
  width: 50px;
  height: 50px;
  box-shadow: inset 0 0 25px rgba(255, 255, 255, 0.8), 0 0 25px rgba(255, 255, 255, 0.5);
  margin-top: -25%;
  animation: 25s linear float2 infinite;
}

.fog3 {
  width: 80px;
  height: 80px;
  box-shadow: inset 0 0 40px rgba(255, 255, 255, 0.8), 0 0 40px rgba(255, 255, 255, 0.5);
  margin-left: 25%;
  animation: 40s ease float2 infinite;
}

.fog4 {
  width: 40px;
  height: 40px;
  margin-left: 60%;
  margin-top: -45%;
  animation: 45s linear float infinite;
  box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.8), 0 0 20px rgba(255, 255, 255, 0.5);
}

.fog5 {
  width: 60px;
  height: 60px;
  margin-left: 50%;
  margin-top: -30%;
  animation: 30s ease float2 infinite;
  box-shadow: inset 0 0 30px rgba(255, 255, 255, 0.8), 0 0 30px rgba(255, 255, 255, 0.5);
}

.fog5 {
  width: 125px;
  height: 125px;
  margin-left: 50%;
  margin-top: 30%;
  animation: 70s ease-out float3 infinite;
  box-shadow: inset 0 0 40px rgba(255, 255, 255, 0.8), 0 0 60px rgba(255, 255, 255, 0.5);
}

.fog6 {
  width: 20px;
  height: 20px;
  margin-left: 50%;
  margin-top: -10%;
  animation: 20s linear float infinite;
  box-shadow: inset 0 0 2px rgba(255, 255, 255, 0.8), 0 0 2px rgba(255, 255, 255, 0.5);
}

.fog7 {
  width: 50px;
  height: 50px;
  margin-left: 0%;
  margin-top: 30%;
  animation: 40s ease-out float3 infinite;
  box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.8), 0 0 20px rgba(255, 255, 255, 0.5);
}

.fog8 {
  width: 80px;
  height: 80px;
  margin-left: 60%;
  margin-top: -20%;
  animation: 40s ease-out float3 infinite;
  box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.8), 0 0 20px rgba(255, 255, 255, 0.5);
}

@keyframes float {
  0% {
    top: 50%;
    left: 10%;
  }
  25% {
    top: 75%;
    left: 25%;
  }
  50% {
    top: 50%;
    left: 35%;
  }
  75% {
    top: 75%;
    left: 25%;
  }
  100% {
    top: 50%;
    left: 10%;
  }
}
@keyframes float2 {
  0% {
    top: 50%;
    left: 10%;
  }
  25% {
    top: 25%;
    left: 15%;
  }
  50% {
    top: 50%;
    left: 45%;
  }
  75% {
    top: 25%;
    left: 15%;
  }
  100% {
    top: 50%;
    left: 10%;
  }
}
@keyframes float3 {
  0% {
    top: 50%;
    left: 25%;
  }
  25% {
    top: 35%;
    left: 0;
  }
  50% {
    top: 10%;
    left: 10%;
  }
  75% {
    top: 35%;
    left: 0%;
  }
  100% {
    top: 50%;
    left: 25%;
  }
}
@media (max-width: 991px) {


  .four0four {
    text-align: center;
    min-width: 100%;
    padding: 0;
    margin: 0;
    height: 200px;
    font-size: 10em;
  }

  .notFound {
    font-size: 1.75em;
    padding: 0;
    margin: 0;
    text-align: center;
  }
}
