.productsSection {
  padding: 4rem 0 5rem 0;
}

.dark.all {
  background-color: #00223b;
}

.light.all {
  background-color: #f9fdff;
}

.light h1 {
  color: #00223b;
}

.dark h1 {
  color: #f0f0f0;
}

.light h2 {
  color: #00223b;
}

.dark h2 {
  color: #00223b;
}

.light p {
  color: #333333;
}

.dark p {
  color: #cccccc;
}

.productCard {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 100%;
  border-radius: 15px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.productImage {
  width: 100%;
  height: auto;
  border-radius: 8px;
  max-height: 320px;
  padding: .8px;
  transition: transform 0.4s ease; /* Add transition for zoom effect */
}

@media (max-width: 768px) {
  .productImage {
      height: 200px;
  }
}

.productCardBody {
  background-color: transparent !important;
  padding: 10px 0;
  text-align: center;
}

.productCardBody h5 {
  margin: 0;
  font-size: 1.2rem;
  font-weight: 600;
}

.priceTag {
  position: absolute;
  top: 10px;
  right: 15px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 1rem;
}

/* Add hover effect for description overlay */
.productCard:hover .productImage {
  transform: scale(.9); /* Slight zoom effect on hover */
}

.overlayContent {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background: linear-gradient(180deg, #cd5118ba, #3190bcaf); /* Gradient background */
  padding: 10px;
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column; /* Ensure subtitle and description are stacked */
  font-size: 22px;
}
.overlayContent p {
  margin: 5px 0; /* Add margin for subtitle and description */
}

.overlayContent .subtitle {
  font-size: 18px;
  font-weight: bold;
}

/* Set text color for light mode */
.light .overlayContent p, .light .overlayContent .subtitle {
  color: #00223b; /* Text color in light mode */
}

.dark .overlayContent p, .dark .overlayContent .subtitle {
  color: #FFFFFF; /* Text color in dark mode */
}

.productCard:hover .overlayContent {
  opacity: 1; /* Show overlay content on hover */
}

.allRtl {
  direction: rtl;
}

/* Modal Layout */
.modalLayout {
  display: flex;
  flex-direction: column; /* Default column layout */
  align-items: center;
  justify-content: center;
}

/* Main image */
.mainImageWrapper {
  width: 100%; /* Make the main image responsive */
  display: flex;
  justify-content: center;
}

.mainImage {
  max-width: 100%; /* Ensure it doesn't overflow */
  height: 300px;
  object-fit: cover;
  border-radius: 8px;
}

/* Gallery below main image by default */
.galleryWrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px; /* Space between main image and gallery */
}

.thumbnailWrapper {
  margin: 0 5px; /* Space between gallery images */
}

.galleryImage {
  width: 70px; /* Fixed size for gallery images */
  height: 70px;
  object-fit: cover;
  border-radius: 8px;
}

/* Responsive behavior for smaller screens */
@media (min-width: 521px) {
  .modelParagrap p{
    font-size: 6px !important;
  }
  .modalLayout {
    flex-direction: row; /* Side by side layout for larger screens */
  }
  
  .galleryWrapper {
    flex-direction: column; /* Gallery images vertically aligned on the left */
    margin-right: 20px; /* Space between gallery and main image */
  }
}

/* Fix for close button positioning */
.MDBModalHeader .btn-close {
  position: absolute;
  right: 20px; /* Ensure the close button stays in place */
  top: 10px;
}

@media (max-width: 520px) {
  /* On smaller screens, adjust modal to be more compact */
  .MDBModalDialog {
    max-width: 90vw; /* Set modal to take 90% of viewport width */
  }

  /* Place the gallery at the bottom of the main image */
  .galleryWrapper {
    margin-top: 15px; /* Space between main image and gallery */
    flex-direction: row; /* Horizontally align gallery images */
  }

  .thumbnailWrapper {
    margin: 0 4px; /* Smaller space between gallery images */
  }

  .galleryImage {
    width: 60px; /* Smaller gallery images for small screens */
    height: 60px;
  }

  .mainImage {
    width: 100%; /* Make the main image responsive */
  }

  /* Adjust the close button */
  .MDBModalHeader .btn-close {
    top: 10px;
    right: 10px;
  }
}

