

.dark.all {
  background-color: #00223b;
}

.light.all {
  background-color: #f9fdff;
}


.header {
  position: relative;
  background: linear-gradient(
    #cd5118 , 
      #3190bcb5
    ),
    url('../../assets/images/LandingPage/blogpage.png') no-repeat center center;
  background-size: cover;
  background-position: center top ;
  color: white;
  height: 500px;
display: flex;
justify-content: center;
align-items: center;
}

.contain {
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.containRtl {
  justify-content: center;
  text-align: center;
}

.header-content {
  text-align: center;
}

.header-contentRtl {
  text-align: center;
}

.header h1 {
  color: #f9fdff;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  max-width: 100%;
}

.header .subtitle {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.subtitleRtl {
  flex-direction: row-reverse;
}

.line {
  width: 30px;
  height: 1px;
  background-color: #E5E5E5;
  display: inline-block;
  margin: 0 10px; 
}

.header .subtitle1 {
  color: #E5E5E5;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.header .subtitle2 {
  color: #E5E5E5;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 484px;
  margin-bottom: -32px;
}

.postContainer {
  padding: 22px;
  text-align: left;
  max-width: 1300px;
}

.rtl .postContainer {
  text-align: right;
}

.post-card {
  max-width: 100%;
  margin: 0 auto;
  background-color: transparent;
  border-radius: 8px;
  box-shadow: none;
}

.imageContainer {
  width: 100%;
  height: 361px;
  border-radius: 5px;
  background: lightgray center / 100% 100% no-repeat;
  margin-bottom: 20px;
  overflow: hidden; 
}

.imageContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.post-content {
  margin: 0 15px 20px 15px;
  color: #f9fdff;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; 
}

.rtl .post-content {
  text-align: right;
}

.light.post-content {
  color: #00223b;
}

.post-title {
  color: #cd5118;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 10px;
}

.rtl .post-title {
  text-align: right;
}

.post-date {
  font-size: 14px;
  margin-bottom: 20px;
}

.post-text {
  font-size: 16px;
  line-height: 1.5;
}
.rtl .post-text {
  text-align: right;
}

.post-subtitle {
  color: #cd5118;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 20px 15px 20px 15px;
}

.post-summary {
  margin: 20px 15px 20px 15px;
  color: #f9fdff;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}
.rtl .post-summary {
  text-align: right;
}

 .post-img{
  width: 600px !important;
}
.light.post-summary {
  color: #00223b;
}

.singleImage {
  margin: auto;
}

.popular-topics .posts {
  display: flex;
  flex-wrap: wrap;
  margin: 10px;
}

.light.popular-topics h2 {
  color: #00223b;
}

.post-card {
  background-color: transparent !important;
  margin: 10px;
  box-shadow: none;
  color: #444;
}

.post-card .cardImage {
  width: 100%;
  height: 200px;
  object-fit: cover;
  cursor: pointer;
}

.post-card .post-content {
  margin: 10px 0;
  padding: 0;
  text-align: left;
}

.post-date {
  color: #f9fdff;
  letter-spacing: 2px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.popular-topics {
  color: white;
  padding: 20px 0;
  margin: 80px 0 80px 0;
  margin: auto;
}

.popular-topics h2 {
  color: #f9fdff;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 25px;
  text-align: left;
}



@media (min-width: 290px) and (max-width: 780px) {
  .header-content {
    max-width: 100%;
  }

  .header h1 {
    font-size: 40px;
  }

 
  .post-title {
    font-size: 25px;
    margin-top: 20px;
  }

  .post-subtitle {
    font-size: 25px;
  }
}

@media (max-width: 768px) {
  .imageContainer {
    height: auto;
    padding-top: 68.4%;
  }

  .imageContainer img {
    object-fit: cover;
  }

  table, thead, tbody, th, td, tr {
    display: block;
    width: 100%;
    max-width: 100%;
    
  }

  thead tr {
    display: none;
  }

  tr {
   max-height: 100%;
width: 100%;

  }

 
td {
  width: 100%;
padding: 1px !important;
  text-align: center;

}

table {
  max-width: 100%; /* Set a maximum width if needed */
  border-collapse: collapse;
  margin-bottom: 2rem !important;

}
table p {
  margin: 0 !important;
}
 
}

.noTranslation{
  font-size: 27px;
  padding:50px ;
  text-align: center;
  
}

.light .noTranslation{
  color: #00223b;
}

.dark .noTranslation{
  color: #f9fdff;
}


.popular-topics.rtl h2 {
  margin-right: 25px;
  text-align: right;
}

.rtlfordate {
  direction: rtl;
  text-align: right;
}

.ltrforapi {
  direction: rtl;
  text-align: center;
}

.post-text {
  max-width: 100%;
  overflow-wrap: break-word;
}

.post-text img {
  max-width: 100%;
  height: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  
}

th {
  background-color: #f2f2f2;
}



.shareContainer {
  max-width: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  flex-wrap: wrap;
}

.shareText {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 15px;
  width: 100%;
  text-align: center;


}

.urlContainer {
  display: flex;
  align-items: center;
  border: 1px solid var(--miscellaneous-alert-menu-action-sheet-separators, rgba(128, 128, 128, 0.55));
  padding: 10px;
  border-radius: 10px;
  background: transparent;
  margin-right: 0.5rem;
}

.urlInput {
  padding: 2px;
  border: none;
  border-radius: 4px;
  margin-right: 10px;
  font-size: 19px;
  font-weight: 700;
}

.light .urlInput , .light .shareText {
  color: #00223b;
}

.dark .urlInput , .dark .shareText{
  color: #f9fdff;
}

.copyButton {
  padding: 10px;
  border: none;
  background-color: var(--Primary, #cd5118);
  color: white;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.socialButtons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.socialButtons button {
  width: 53px;
  height: 53px;
  padding: 10px;
  margin: 0 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.facebookButton {
  background-color: #3b5998;
  color: white;
}

.twitterButton {
  background-color: #1da1f2;
  color: white;
}

.linkedinButton {
  background-color: #0077b5;
  color: white;
}


@media (max-width: 780px) {
  .shareText {
    font-size: 23px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 15px;
    width: 100%;
    text-align: center;
  
  }
  .shareContainer {
    width: 100%;
    max-width: 100%;
  }

  .urlContainer {
    max-width: 100%;
    margin-bottom: 10px;
  }

  .urlInput {
    width: 100%;
  }

  .socialButtons {

    margin-bottom: 10px;
    margin-right: 10px;
  }

  .socialButtons button {
    margin-bottom: 10px;
  }
} 


 video {
  max-width: 100%;
  height: 100%;
  border: 0;
}





.rtlSecond{
  text-align: right;
  direction: rtl;
}