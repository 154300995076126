.loaderContainer {
  height: 100vh; /* Use full viewport height */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}

.dark {
  background-color: #00223b;
}

.light {
  background-color: #f9fdff;
}

.loader {
  display: flex;
  flex-direction: column; /* Ensures vertical alignment */
  align-items: center;
  justify-content: center;
}

.lodingImg {
  margin-top: 3rem;
  max-width: 90%;
  max-height: 50%; /* Adjust based on how large you want the image to be */
  animation: shake 0.2s ease-in-out infinite alternate;
}

@keyframes shake {
  0% {
    transform: translateY(-1%);
  }
  100% {
    transform: translateY(3%);
  }
}

.Elsharq {
  text-align: center;
  font-size: 24px;
  color: #cd5018;
  letter-spacing: 5px;
  font-weight: 700;
  margin-top: 20px; /* Adds space between image and text */
  margin-left: 0; /* Centers the text */
}
