
.dark.all{
  background-color: #00223b;
color: #f9fdff;
}

.light.all{
  background-color: #f9fdff;
  color: #00223b;
 
}

.header {
  position: relative;
  background: linear-gradient(
    #cd5118 , 
      #3190bcb5
    ),
    url('../../assets/images/LandingPage/blogpage.png') no-repeat center center;
  background-size: cover;
  background-position: center top ;
  color: white;
  height: 500px;
display: flex;
justify-content: center;
align-items: center;
}



.header-content {
  text-align: center;
  margin: auto;
}

/* Apply text-align right for RTL */
.header-contentRtl {
  text-align: center;
  margin: auto;
}

.header h1 {
  color: #f9fdff;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 800px;
  max-width: 100%;
  text-align: center;
  margin: auto;
}
.header p{
  color: #f9fdff;
  font-size: 15px;
  font-weight: 700;
  line-height: 22.5px;
  width: fit-content;
  padding: 5px 10px;
  margin-bottom: 15px;
  max-width: 100%;
  border-radius: 8px;
  text-align: center;
  margin: auto;
}



.header-contentRtl h1{
  margin-top: .5rem;
  margin-bottom: .5rem;
}


.popular-topics {
  color: white;
  padding: 20px 0;
  margin: 80px 0 80px 0;
  margin: auto;
}

.popular-topics h2 {
  color: #f9fdff;
 
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 25px;
  text-align: left;
  margin-bottom: 1.5rem;
}

.popular-topics .category-buttons {
  display: flex;
  flex-wrap: wrap; /* Allow buttons to wrap to the next line */
  margin-left: 30px;
  margin-bottom: 20px;
}


.popular-topics .category-buttons p {
  cursor: pointer;
  margin: 0 10px; /* Adjust margin for smaller screens */
  font-size: 18px; /*14 Adjust font size for smaller screens */
  font-weight: 600; /*500*/
  line-height: 25px;
  background-color: #cd5118;
padding: .1rem .5rem;
border-radius: 1rem;

}

.light .active{
  color:  #00223b !important;
  
}
.light .active{
  color:  #f0f0f0 !important;
}


.dark .active{
  color:  #00223b !important;
}
.dark .active{
  color:  #00223b !important;
}



.popular-topics .posts {
  display: flex;
    flex-wrap: wrap;
  margin: 10px;

  
}
.light .post-card{
  box-shadow: rgba(22, 49, 67, 0.35) 0px 5px 15px;
}

.dark .post-card{
  box-shadow: rgba(10, 68, 130, 0.407) 0px 7px 29px 0px;

}


.post-card {
  background-color: transparent  !important;
  margin: 10px;
  color: #444;
  overflow: hidden; 
  cursor: pointer;
height: 500px;

}


.post-card .cardImage {
  width: 100%;
  height: 300px;
  cursor: pointer;
  background-size: 100% 100%;
  background: lightgray center / 100% 100% no-repeat;
  transition: transform 0.3s ease-in-out; /* Add transition for smooth effect */
}

.post-card:hover .cardImage {
  transform: scale(1.09); /* Scale the image on hover */
}

.post-card .post-content {
margin: 10px 0;
padding: 1rem;
text-align: left;

}

.post-date {
  color: #f9fdff;
letter-spacing: 2px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

 .post-title {
  color: #f9fdff;
 
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; 
}

.post-summary{
  color: #f9fdff;

font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 166.667% */


}


.imageContainer {
  position: relative;
  overflow: hidden; /* Ensure the image doesn't overflow the container */

}

.imagePlaceholder {
  width: 100%;
  height: 200px;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #aaa;
  font-size: 1.2rem;
}

.categoryBadge {
  position: absolute;
  border-radius: 2rem;
  padding: .3rem .4rem;
  top: 10px;
  right: 10px;
  color: white;
  font-size: 13px;
}




.light .No-Posts{
  color: #2B2A29;
  text-align: center !important;
  font-size: 2.25rem;
  line-height: 1.3;
  font-weight: 600;


}

.dark .No-Posts{
  color: #f9fdff;
  text-align: center !important;
  font-size: 2.25rem;
  line-height: 1.3;
  font-weight: 600;


}


  
@media (min-width: 290px) and (max-width: 780px) {
  .header {
    position: relative;
    background: linear-gradient(
      #cd5118 , 
      #3190bcb5
      ),
      url('../../assets/images/LandingPage/blogpage.png') no-repeat center center;
    background-size: cover;

    color: white;
  
  }
  
  .header-content{
 max-width: 100%;


  }
  .header h1{
    font-size: 17px;
    line-height: 1.5rem;
    font-size: 17px;
max-width:96%;
  }
 
  .header  .subtitle2 {
    color: #E5E5E5;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.2;
    width: 89%;
  }
  .header p{
    color: #E5E5E5;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.2;
 
  }




 
  
  .popular-topics .category-buttons p {
    margin: 5px; 
    font-size: 13px; 
    font-weight: 700;
  }

  .popular-topics.rtl .category-buttons p {
    margin: 5px 5px 5px 5px !important;
  }

  .light .No-Posts{
    color: #2B2A29;
    text-align: center !important;
    font-size: 1.2rem;
    line-height: 1.3;
    font-weight: 600;
  
  
  }
  
  .dark .No-Posts{
    color: #f9fdff;
    text-align: center !important;
    font-size: 1.2rem;
    line-height: 1.3;
    font-weight: 600;
  
  
  }

  }
  
  
  .light.popular-topics h2 {
    color: #2B2A29;
  }
  .light.post-content ,.light .post-date ,.light .post-summary ,.light .post-title {
    color: #2B2A29;
  }

  
 

  .light.post-title ,.light.post-summary {
    color: #2B2A29;

  }
.light.category-buttons p{
  color: #2B2A29;
}


  .dark.popular-topics h2 {
    color: #f9fdff;
  }
  .dark.post-content .post-date ,.post-summary ,.post-title {
    color: #f9fdff;
  }

  .dark.post-title ,.dark.post-summary{
    color: #f9fdff;

  }

  .error{
    background-color: rgb(169, 23, 23);
    color: #f9fdff;
    font-size: 20px;
    text-align: center;
  }



  




/* .rtl.popular-topics *{
  text-align: right;
} */
.popular-topics.rtl *{
  text-align: right;
  direction: rtl;
}

.popular-topics.rtl h2 {
  margin-right: 25px;
  text-align: right;
}

.popular-topics.rtl .category-buttons {
  margin-right: 30px;
}

.popular-topics.rtl .category-buttons p {
  margin: 0 17px 0 17px;
}



.rtlSecond *{
  text-align: right;
  direction: rtl;
    
  }


  .rtlforenglish {
    direction: rtl;
    text-align: right !important;
  }

  .rtlfordate {
    direction: rtl;
    text-align: right !important;
  }


  

  