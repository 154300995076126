.Footer{
    background-color: #3190bc;
padding: 2rem;

}
.Footer a{
    color: #f9fdff;

}
.Footer p{
    color: #f9fdff;

}
.Footer h6{
    color: #FFF;

font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 25px; 
}

.Frtl p  {
      font-size: 19px;

    text-align: right !important;
}

.Frtl a  {
    font-size: 19px;
    text-align: right !important;
}
.Frtl h6  {
  font-size: 25px;
    text-align: right !important;
}

.HeadquarterPlac{
    width: 70%;
max-width: 100%;
font-size: 12px;
}

.Frtl .HeadquarterPlac{
    width: 100%;
    max-width: 100%;
    font-size: 17px;
}



@media (min-width: 250px) and (max-width: 580px) {
  .Footer{
    padding: 30px !important ;
  }
    .custom-cols {
      flex-basis: 50%!important;
      max-width: 50%!important;
    }
    .Footer p{
        font-size: 12px;
    }
    .Footer h6{
        font-size: 15px;
    }
    .Footer a{
      font-size: 12px;
    }
  

    
  }

